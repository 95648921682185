import { createSlice } from '@reduxjs/toolkit';
import { replaceDownstreamState } from 'lib/immer';
import {
  changePassword,
  createAccount,
  retrieveCustomer,
  saveAddress,
  saveUserInformation,
} from 'store/customer/thunks';
import { CustomerState, DownstreamCustomerState } from 'store/customer/types';

export const initialState: CustomerState = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  addresses: [],
  defaultBillingAddressId: '',
  defaultShippingAddressId: '',
  version: 0,
  inProgress: {
    retrieveCustomer: false,
    saveAddress: false,
    createAccount: false,
    saveUserInformation: false,
    changePassword: false,
  },
  errors: {
    retrieveCustomer: '',
    createAccount: '',
    saveAddress: '',
    saveUserInformation: '',
    changePassword: '',
  },
  complete: {
    createAccount: false,
    saveUserInformation: false,
    changePassword: false,
  },
};

const replaceState = replaceDownstreamState<
  DownstreamCustomerState,
  CustomerState
>({
  omit: {
    errors: true,
    inProgress: true,
  },
});

const { reducer, actions } = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    resetCustomer() {
      return initialState;
    },
    resetCustomerCompletions(state) {
      state.complete = initialState.complete;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(retrieveCustomer.pending, state => {
        state.errors.retrieveCustomer = initialState.errors.retrieveCustomer;
        state.inProgress.retrieveCustomer = true;
      })
      .addCase(retrieveCustomer.rejected, (state, action) => {
        state.errors.retrieveCustomer = action.error.message as string;
        state.inProgress.retrieveCustomer = false;
      })
      .addCase(retrieveCustomer.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.retrieveCustomer = '';
        state.inProgress.retrieveCustomer = false;
      });

    builder
      .addCase(saveUserInformation.pending, state => {
        state.complete.saveUserInformation = false;
        state.errors.saveUserInformation = '';
        state.inProgress.saveUserInformation = true;
      })
      .addCase(saveUserInformation.rejected, (state, action) => {
        state.errors.saveUserInformation = action.error.message as string;
        state.inProgress.saveUserInformation = false;
      })
      .addCase(saveUserInformation.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.complete.saveUserInformation = true;
        state.errors.saveUserInformation = '';
        state.inProgress.saveUserInformation = false;
      });

    builder
      .addCase(changePassword.pending, state => {
        state.complete.changePassword = false;
        state.errors.changePassword = '';
        state.inProgress.changePassword = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.errors.changePassword = action.error.message as string;
        state.inProgress.changePassword = false;
      })
      .addCase(changePassword.fulfilled, state => {
        state.complete.changePassword = true;
        state.errors.changePassword = '';
        state.inProgress.changePassword = false;
      });

    builder
      .addCase(saveAddress.pending, state => {
        state.errors.saveAddress = '';
        state.inProgress.saveAddress = true;
      })
      .addCase(saveAddress.rejected, (state, action) => {
        state.errors.saveAddress = action.error.message as string;
        state.inProgress.saveAddress = initialState.inProgress.saveAddress;
      })
      .addCase(saveAddress.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.saveAddress = '';
        state.inProgress.saveAddress = false;
      });

    builder
      .addCase(createAccount.pending, state => {
        state.errors.createAccount = '';
        state.complete.createAccount = false;
        state.inProgress.createAccount = true;
      })
      .addCase(createAccount.rejected, (state, action) => {
        state.errors.createAccount = action.error.message as string;
        state.inProgress.createAccount = false;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        replaceState(state, action.payload);
        state.errors.createAccount = '';
        state.inProgress.createAccount = false;
        state.complete.createAccount = true;
      });
  },
});

export const { resetCustomer, resetCustomerCompletions } = actions;
export default reducer;
