import { type Asset } from 'contentful';
import { fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import { IMediaSource, type IMedia } from 'generated/contentful';
import { reportTypeErrors } from 'lib/reportTypeErrors';
import returnValidModel from 'lib/returnValidModel';
import { removeUndefinedTopLevelKeys } from 'lib/util';
import {
  MediaAssetModel,
  MediaModel,
  mediaModel,
  MediaSourceModel,
} from 'models/contentful/media/types';
import { ContentModeller } from 'models/contentful/shared/types';

export const CONTENTFUL_URI = '//images.ctfassets.net/3a3g2h3dvhht/';
export const IMGIX_URI = '//perfectmoment-content.imgix.net/';

export const toMediaSourceAsset = (
  fields: Asset['fields']
): MediaAssetModel => ({
  url: fields.file?.url?.replace(CONTENTFUL_URI, IMGIX_URI),
  description: fields.description ?? null,
  title: fields.title ?? null,
  width: fields.file?.details?.image?.width ?? null,
  height: fields.file?.details?.image?.height ?? null,
});

const createMediaSource = (
  mediaSource?: IMediaSource
): MediaSourceModel | null => {
  if (mediaSource === undefined) {
    return null;
  }

  const {
    fields: { image, lowResolutionVideo, highResolutionVideo },
  } = mediaSource;

  const source: MediaSourceModel = {
    image: toMediaSourceAsset(image.fields),
    lowResolutionVideo: lowResolutionVideo?.fields.file.url
      ? toMediaSourceAsset(lowResolutionVideo?.fields)
      : null,
    highResolutionVideo: highResolutionVideo?.fields.file.url
      ? toMediaSourceAsset(highResolutionVideo.fields)
      : null,
  };

  return source;
};

const toMedia: ContentModeller<IMedia, MediaModel> = ({ content }) => {
  const model = removeUndefinedTopLevelKeys({
    xs: createMediaSource(content.fields.xs),
    s: createMediaSource(content.fields.s),
    m: createMediaSource(content.fields.m),
    l: createMediaSource(content.fields.l),
    xl: createMediaSource(content.fields.xl),
    xxl: createMediaSource(content.fields.xxl),
    showVideoControls: Boolean(content.fields.showVideoControls),
  }) as MediaModel;

  return pipe(
    mediaModel.decode(model),
    fold(
      reportTypeErrors({
        model: 'Media',
        id: content.sys.id,
        fallback: null,
      }),
      returnValidModel
    )
  );
};

export default toMedia;
